<script lang="ts" setup>
import { computed } from 'vue';
import type { StyleValue } from 'vue';

type OverscrollBehavior = 'auto' | 'contain' | 'none';

interface Props {
  light?: boolean;
  overscrollBehavior?: OverscrollBehavior | [OverscrollBehavior, OverscrollBehavior];
  scroll?: 'x' | 'y' | 'both';
}

const props = withDefaults(defineProps<Props>(), {
  light: false,
  scroll: 'both',
  overscrollBehavior: 'contain',
});

const styles = computed(() => {
  const result: Partial<StyleValue> = {};

  switch (props.scroll) {
    case 'x':
      result.overflowX = 'auto';
      result.overflowY = 'hidden';
      break;
    case 'y':
      result.overflowY = 'auto';
      result.overflowX = 'hidden';
      break;
    default:
      result.overflow = 'auto';
      break;
  }

  result.overscrollBehavior = Array.isArray(props.overscrollBehavior)
    ? props.overscrollBehavior.join(' ')
    : props.overscrollBehavior;

  return result;
});
</script>

<template>
  <div :class="props.light ? $style.light : $style.default" :style="styles">
    <slot />
  </div>
</template>

<style lang="scss" module>
@use '../../styles/shared';

.default {
  @include shared.scrollbar();
  scrollbar-gutter: stable;
}

.light {
  @include shared.scrollbar-light();
  scrollbar-gutter: stable;
}
</style>
