<script lang="ts" setup>
import { provide, computed } from 'vue';
import { useVModel } from '@vueuse/core';
import { useId } from '../../composables';
import { OB_TABS_CONTEXT } from './shared';
import ObTabsButton from './ob-tabs-button.vue';
import ObTabsList from './ob-tabs-list.vue';

interface Props {
  selectionFollowFocus?: boolean;
  items?: Array<{ label: string; value: string }>;
  modelValue?: string | null;
  variant?: 'primary' | 'secondary';
}

const props = withDefaults(defineProps<Props>(), {
  selectionFollowFocus: false,
  items: () => [],
  modelValue: null,
  variant: 'primary',
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

const modelValue = useVModel(props, 'modelValue', emit, {
  passive: true,
  defaultValue: null,
});

const id = useId();

provide(OB_TABS_CONTEXT, {
  variant: computed(() => props.variant),
  selectionFollowFocus: computed(() => props.selectionFollowFocus),
  setValue: (value: string) => {
    modelValue.value = value;
  },
  isValueSelected: (value: string) => modelValue.value === value,
  id,
});
</script>

<template>
  <div>
    <slot name="tabslist">
      <ObTabsList>
        <slot name="items">
          <template v-for="option in props.items">
            <slot name="item" v-bind="option">
              <ObTabsButton :value="option.value">
                {{ option.label }}
              </ObTabsButton>
            </slot>
          </template>
        </slot>
      </ObTabsList>
    </slot>
    <slot v-bind="{ selected: modelValue }" />
  </div>
</template>
