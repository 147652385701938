<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useElementHover, useFocus } from '@vueuse/core';
import { areValuesEqual, hasSlotContent } from '../../utils';
import { ObPrimitiveCheckbox } from '../primitive-checkbox';

interface Props {
  ariaLabel?: string;
  label?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  invalid?: boolean;
  readonly?: boolean;
  multiselect?: boolean;
  value?: any;
  falseValue?: any;
  modelValue?: any;
  trackBy?: string | ((value: unknown) => unknown);
  // TODO: autofocus
}

const props = withDefaults(defineProps<Props>(), {
  ariaLabel: undefined,
  label: undefined,
  disabled: false,
  indeterminate: false,
  invalid: false,
  readonly: false,
  multiselect: false,
  value: true,
  falseValue: false,
  modelValue: undefined,
  trackBy: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: any];
}>();

const rootRef = ref();
const inputRef = ref();

const hovered = useElementHover(rootRef);
const { focused } = useFocus(inputRef);

const modelValue = ref(props.modelValue ?? props.multiselect ? [] : props.falseValue);

watch(
  () => props.modelValue,
  (value) => {
    modelValue.value = value;
  },
  {
    immediate: true,
  },
);

const checked = computed<boolean>(() => {
  if (props.multiselect) {
    return modelValue.value.some((item: unknown) =>
      areValuesEqual(item, props.value, props.trackBy),
    );
  }

  return areValuesEqual(modelValue.value, props.value, props.trackBy);
});

function onChange(event: Event): void {
  if (props.disabled) {
    return;
  }

  const { checked } = event.target as HTMLInputElement;

  if (props.multiselect) {
    if (checked) {
      modelValue.value.push(props.value);
    } else {
      modelValue.value = modelValue.value.filter(
        (item: unknown) => !areValuesEqual(item, props.value, props.trackBy),
      );
    }
  } else {
    modelValue.value = checked ? props.value : props.falseValue;
  }

  emit('update:modelValue', modelValue.value);
}
</script>

<template>
  <label ref="rootRef" :class="[$style.root, { [$style.disabled]: props.disabled }]">
    <input
      ref="inputRef"
      :aria-checked="props.indeterminate ? 'mixed' : checked"
      :aria-invalid="props.invalid ? true : undefined"
      :aria-readonly="props.readonly ? true : undefined"
      :aria-label="ariaLabel"
      :checked="checked"
      :disabled="props.disabled"
      :readonly="props.readonly"
      type="checkbox"
      :class="$style.input"
      @change="onChange"
    />
    <ObPrimitiveCheckbox
      :checked="checked"
      :disabled="props.disabled"
      :focused="focused"
      :hovered="hovered && !props.disabled"
      :indeterminate="props.indeterminate"
      :invalid="props.invalid"
    />
    <span v-if="label || hasSlotContent($slots.default)" :class="$style.label">
      <slot>{{ props.label }}</slot>
    </span>
  </label>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  color: colors.$primary;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.input {
  @include shared.sr-only();
}

.label {
  margin-left: 8px;
  flex: 1 1 0%;
  min-width: 0;
}

.disabled {
  color: colors.$surface-40;
  cursor: not-allowed;
}
</style>
