import { setNativeFocused } from './set-native-focused';
import { obGetClosestFocusable } from './get-closest-focusable';

export function moveFocusInside(target: HTMLElement): HTMLElement | null {
  const focusable = obGetClosestFocusable({
    initial: target,
    root: target,
  });

  if (focusable) {
    setNativeFocused(focusable, true);
    return focusable;
  }

  return null;
}
