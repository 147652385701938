<script lang="ts" setup>
import { useId } from '../../composables';
import { ObFieldLabel } from '../field-label';
import { ObFieldHint } from '../field-hint';
import { ObFieldError } from '../field-error';

interface Props {
  error?: string;
  hint?: string;
  id?: string;
  label?: string;
  labelId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  error: undefined,
  hint: undefined,
  id: () => useId(),
  label: undefined,
  labelId: () => useId(),
});
</script>

<template>
  <div>
    <div v-if="props.label" :class="$style.label">
      <ObFieldLabel :id="props.labelId" :for="props.id">{{ props.label }}</ObFieldLabel>
    </div>
    <slot v-bind="{ id: props.id, labelId: props.labelId }" />
    <div v-if="props.hint" :class="$style.hint">
      <ObFieldHint>{{ props.hint }}</ObFieldHint>
    </div>
    <div v-if="props.error" :class="$style.error">
      <ObFieldError>{{ props.error }}</ObFieldError>
    </div>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.label {
  margin-bottom: 8px;
  text-align: left;
}

.hint,
.error {
  margin-top: 8px;
  text-align: left;
}
</style>
