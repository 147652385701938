import { isUndefined } from 'lodash-es';
import { getValueByKey } from './get-value-by-key';

export function areValuesEqual(
  value1: unknown,
  value2: unknown,
  trackBy?: string | ((value: unknown) => unknown),
) {
  const trackedValue1 = trackBy ? getValueByKey(value1, trackBy) : value1;
  const trackedValue2 = trackBy ? getValueByKey(value2, trackBy) : value2;

  if (isUndefined(trackedValue1) || isUndefined(trackedValue2)) {
    return false;
  }

  return trackedValue1 === trackedValue2;
}
