import { watch, computed, effectScope, getCurrentScope, toValue } from 'vue';
import type { MaybeRef, EffectScope } from 'vue';
import {
  unrefElement,
  useMutationObserver,
  useEventListener,
  tryOnScopeDispose,
} from '@vueuse/core';
import type { MaybeElementRef } from '@vueuse/core';
import { focusable } from 'tabbable';
import { isMacOS } from '../../utils';

type Direction = 'previous' | 'next' | 'start' | 'end';
type Key =
  | 'ArrowLeft'
  | 'ArrowDown'
  | 'ArrowUp'
  | 'ArrowRight'
  | 'Tab'
  | 'Home'
  | 'End'
  | 'PageUp'
  | 'PageDown'
  | 'Backspace';

export interface UseFocusZoneOptions {
  disabled?: MaybeRef<boolean>;
  focusOutBehavior?: 'stop' | 'wrap';
  bindKeys?: Key[];
  focusableElementFilter?: (element: HTMLElement) => boolean;
  getNextFocusable?: (
    direction: Direction,
    from: Element | undefined,
    event: KeyboardEvent,
  ) => HTMLElement | undefined;
  focusInStrategy?:
    | 'first'
    | 'closest'
    | 'previous'
    | ((previousFocusedElement: Element) => HTMLElement | undefined);
}

const KEY_TO_DIRECTION: Record<Key, Direction> = {
  ArrowLeft: 'previous',
  ArrowDown: 'next',
  ArrowUp: 'previous',
  ArrowRight: 'next',
  Tab: 'next',
  Home: 'start',
  End: 'end',
  PageUp: 'start',
  PageDown: 'end',
  Backspace: 'previous',
};

export function useFocusZone(containerRef: MaybeElementRef, options?: UseFocusZoneOptions): void {
  const focusOutBehavior = options?.focusOutBehavior ?? 'stop';
  const focusInStrategy = options?.focusInStrategy ?? 'previous';
  const bindKeys = options?.bindKeys ?? ['ArrowUp', 'ArrowDown', 'Home', 'End'];
  const containerElement = computed(() => unrefElement(containerRef));
  const disabled = computed(() => toValue(options?.disabled) ?? false);

  const initialTabIndexes = new WeakMap<HTMLElement, string | null>();
  const focusableElements: HTMLElement[] = [];
  let currentFocusedElement: HTMLElement | undefined;

  function getFirstFocusableElement(): HTMLElement | undefined {
    return focusableElements[0];
  }

  function getCurrentFocusedIndex() {
    if (!currentFocusedElement) {
      return 0;
    }

    const focusedIndex = focusableElements.indexOf(currentFocusedElement);
    const fallbackIndex = currentFocusedElement === containerElement.value ? -1 : 0;

    return focusedIndex !== -1 ? focusedIndex : fallbackIndex;
  }

  function updateFocusedElement(to?: HTMLElement) {
    const from = currentFocusedElement;
    currentFocusedElement = to;

    if (from && from !== to && initialTabIndexes.has(from)) {
      from.setAttribute('tabindex', '-1');
    }

    to?.setAttribute('tabindex', '0');
  }

  function followsInDocument(firstElement: HTMLElement, secondElement: HTMLElement) {
    return (
      (secondElement.compareDocumentPosition(firstElement) & Node.DOCUMENT_POSITION_PRECEDING) > 0
    );
  }

  function findInsertionIndex(elementsToInsert: HTMLElement[]) {
    const firstElementToInsert = elementsToInsert[0];

    if (focusableElements.length === 0) {
      return 0;
    }

    let iMin = 0;
    let iMax = focusableElements.length - 1;
    while (iMin <= iMax) {
      const i = Math.floor((iMin + iMax) / 2);
      const element = focusableElements[i];

      if (followsInDocument(firstElementToInsert, element)) {
        iMax = i - 1;
      } else {
        iMin = i + 1;
      }
    }

    return iMin;
  }

  function startFocusManagement(...elements: HTMLElement[]) {
    const filteredElements = options?.focusableElementFilter
      ? elements.filter((item) => options.focusableElementFilter?.(item) ?? true)
      : elements;

    if (filteredElements.length === 0) {
      return;
    }

    focusableElements.splice(findInsertionIndex(filteredElements), 0, ...filteredElements);

    filteredElements.forEach((element) => {
      if (!initialTabIndexes.has(element)) {
        initialTabIndexes.set(element, element.getAttribute('tabindex'));
      }

      element.setAttribute('tabindex', '-1');
    });

    if (!currentFocusedElement) {
      updateFocusedElement(getFirstFocusableElement());
    }
  }

  function stopFocusManagement(...elements: HTMLElement[]) {
    elements.forEach((element) => {
      const focusableElementIndex = focusableElements.indexOf(element);

      if (focusableElementIndex >= 0) {
        focusableElements.splice(focusableElementIndex, 1);
      }

      const savedIndex = initialTabIndexes.get(element);

      if (savedIndex !== undefined) {
        if (savedIndex === null) {
          element.removeAttribute('tabindex');
        } else {
          element.setAttribute('tabindex', savedIndex);
        }
        initialTabIndexes.delete(element);
      }

      if (element === currentFocusedElement) {
        updateFocusedElement(getFirstFocusableElement());
      }
    });
  }

  function getDirection(event: KeyboardEvent): Direction {
    if (event.key === 'Tab' && event.shiftKey) {
      return 'previous';
    }

    const isMac = isMacOS();

    if ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
        return 'start';
      } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
        return 'end';
      }
    }

    return KEY_TO_DIRECTION[event.key as Key];
  }

  function shouldIgnoreFocusHandling(keyboardEvent: KeyboardEvent, activeElement: Element | null) {
    const key = keyboardEvent.key;

    // Get the number of characters in `key`, accounting for double-wide UTF-16 chars. If keyLength
    // is 1, we can assume it's a "printable" character. Otherwise it's likely a control character.
    // One exception is the Tab key, which is technically printable, but browsers generally assign
    // its function to move focus rather than type a <TAB> character.
    const keyLength = [...key].length;

    const isTextInput =
      (activeElement instanceof HTMLInputElement && activeElement.type === 'text') ||
      activeElement instanceof HTMLTextAreaElement;

    if (isTextInput && (keyLength === 1 || key === 'Home' || key === 'End')) {
      return true;
    }

    if (activeElement instanceof HTMLSelectElement) {
      // Regular typeable characters change the selection, so ignore those
      if (keyLength === 1) {
        return true;
      }
      // On macOS, bare ArrowDown opens the select, so ignore that
      if (key === 'ArrowDown' && isMacOS() && !keyboardEvent.metaKey) {
        return true;
      }
      // On other platforms, Alt+ArrowDown opens the select, so ignore that
      if (key === 'ArrowDown' && !isMacOS() && keyboardEvent.altKey) {
        return true;
      }
    }

    if (activeElement instanceof HTMLTextAreaElement && (key === 'PageUp' || key === 'PageDown')) {
      return true;
    }

    if (isTextInput) {
      const textInput = activeElement as HTMLInputElement | HTMLTextAreaElement;
      const { selectionStart, selectionEnd } = textInput;
      const cursorAtStart = selectionStart === 0 && selectionEnd === 0;
      const cursorAtEnd =
        selectionStart === textInput.value.length && selectionEnd === textInput.value.length;

      // When in a text area or text input, only move focus left/right if at beginning/end of the field
      if (key === 'ArrowLeft' && !cursorAtStart) {
        return true;
      }

      if (key === 'ArrowRight' && !cursorAtEnd) {
        return true;
      }

      // When in a text area, only move focus up/down if at beginning/end of the field
      if (textInput instanceof HTMLTextAreaElement) {
        if (key === 'ArrowUp' && !cursorAtStart) {
          return true;
        }
        if (key === 'ArrowDown' && !cursorAtEnd) {
          return true;
        }
      }
    }

    return false;
  }

  function initListeners() {
    useMutationObserver(
      containerRef,
      (mutations) => {
        mutations.forEach(({ removedNodes }) => {
          removedNodes.forEach((removedNode) => {
            if (removedNode instanceof HTMLElement) {
              stopFocusManagement(
                ...(focusable(removedNode, {
                  includeContainer: true,
                  displayCheck: 'legacy-full',
                }) as HTMLElement[]),
              );
            }
          });
        });

        mutations.forEach(({ addedNodes }) => {
          addedNodes.forEach((addedNode) => {
            if (addedNode instanceof HTMLElement) {
              startFocusManagement(
                ...(focusable(addedNode, { includeContainer: true }) as HTMLElement[]),
              );
            }
          });
        });
      },
      {
        subtree: true,
        childList: true,
      },
    );

    let elementIndexFocusedByClick: number | undefined = undefined;
    let lastKeyboardFocusDirection: Direction | undefined = undefined;

    useEventListener(containerElement, 'mousedown', (event: PointerEvent) => {
      if (event.target instanceof HTMLElement && event.target !== document.activeElement) {
        elementIndexFocusedByClick = focusableElements.indexOf(event.target);
      }
    });

    useEventListener(containerElement, 'focusin', (event: FocusEvent) => {
      if (event.target instanceof HTMLElement) {
        if (elementIndexFocusedByClick !== undefined) {
          if (elementIndexFocusedByClick >= 0) {
            if (focusableElements[elementIndexFocusedByClick] !== currentFocusedElement) {
              updateFocusedElement(focusableElements[elementIndexFocusedByClick]);
            }
          }
          elementIndexFocusedByClick = undefined;
        } else {
          if (focusInStrategy === 'previous') {
            updateFocusedElement(event.target);
          } else if (focusInStrategy === 'closest' || focusInStrategy === 'first') {
            if (
              event.relatedTarget instanceof Element &&
              !containerElement.value?.contains(event.relatedTarget)
            ) {
              // If we are coming from outside of container
              //  put focus on the first element if we come from above
              //  or on the last if we come from below.
              const targetElementIndex =
                lastKeyboardFocusDirection === 'previous' ? focusableElements.length - 1 : 0;
              focusableElements[targetElementIndex]?.focus();
              return;
            }
            updateFocusedElement(event.target);
          } else if (typeof focusInStrategy === 'function') {
            if (
              event.relatedTarget instanceof Element &&
              !containerElement.value?.contains(event.relatedTarget)
            ) {
              const elementToFocus = focusInStrategy(event.relatedTarget);
              const requestedFocusElementIndex = elementToFocus
                ? focusableElements.indexOf(elementToFocus)
                : -1;

              if (requestedFocusElementIndex >= 0 && elementToFocus instanceof HTMLElement) {
                elementToFocus.focus();
                return;
              }
            }

            updateFocusedElement(event.target);
          }
        }
      }
      lastKeyboardFocusDirection = undefined;
    });

    if (focusInStrategy === 'closest') {
      useEventListener(
        'keydown',
        (event) => {
          if (event.key === 'Tab') {
            lastKeyboardFocusDirection = getDirection(event);
          }
        },
        { capture: true },
      );
    }

    useEventListener(containerElement, 'keydown', (event: KeyboardEvent) => {
      if (
        !(event.key in KEY_TO_DIRECTION) ||
        event.defaultPrevented ||
        !bindKeys.includes(event.key as Key) ||
        shouldIgnoreFocusHandling(event, document.activeElement)
      ) {
        return;
      }

      const direction = getDirection(event);

      let nextElementToFocus: HTMLElement | undefined = undefined;

      if (options?.getNextFocusable) {
        nextElementToFocus = options.getNextFocusable(
          direction,
          document.activeElement ?? undefined,
          event,
        );
      }

      if (!nextElementToFocus) {
        const lastFocusedIndex = getCurrentFocusedIndex();

        let nextFocusedIndex = lastFocusedIndex;

        switch (direction) {
          case 'previous':
            nextFocusedIndex -= 1;
            break;
          case 'start':
            nextFocusedIndex = 0;
            break;
          case 'next':
            nextFocusedIndex += 1;
            break;
          default:
            nextFocusedIndex = focusableElements.length - 1;
            break;
        }

        if (nextFocusedIndex < 0) {
          nextFocusedIndex =
            focusOutBehavior === 'wrap' && event.key !== 'Tab' ? focusableElements.length - 1 : 0;
        }

        if (nextFocusedIndex >= focusableElements.length) {
          nextFocusedIndex =
            focusOutBehavior === 'wrap' && event.key !== 'Tab' ? 0 : focusableElements.length - 1;
        }

        if (lastFocusedIndex !== nextFocusedIndex) {
          nextElementToFocus = focusableElements[nextFocusedIndex];
        }
      }

      if (nextElementToFocus) {
        lastKeyboardFocusDirection = direction;
        nextElementToFocus.focus();
      }

      if (event.key !== 'Tab' || nextElementToFocus) {
        event.preventDefault();
      }
    });
  }

  const parentScope = getCurrentScope();
  let scope: EffectScope | undefined;

  function deactivate() {
    stopFocusManagement(...focusableElements);
    if (scope) {
      scope.stop();
      scope = undefined;
    }
  }

  function activate() {
    if (!containerElement.value) {
      return;
    }

    startFocusManagement(...(focusable(containerElement.value) as HTMLElement[]));

    if (!scope) {
      scope = parentScope?.run(() => effectScope()) || effectScope();
      scope.run(() => {
        initListeners();
      });
    }
  }

  watch(
    [containerElement, disabled],
    () => {
      if (!containerElement.value || disabled.value) {
        deactivate();
        return;
      }

      activate();
    },
    { immediate: true },
  );

  tryOnScopeDispose(() => deactivate());
}
