import { ref, computed, nextTick, provide, inject, watch, unref } from 'vue';
import type { InjectionKey, Ref, WritableComputedRef, MaybeRef } from 'vue';
import { useMutationObserver, useEventListener, unrefElement } from '@vueuse/core';
import {
  obGetClosestFocusable,
  getNativeFocused,
  blurNativeFocused,
  setNativeFocused,
  isElementEditable,
} from '../../utils';
import { Keys } from '../../shared/enums';
import { useFocusScope } from '../use-focus-scope';

type FocusStrategy = 'first' | 'last';

interface UsePopoverReturn {
  active: WritableComputedRef<boolean>;
  activate: (focusStrategy?: FocusStrategy) => void;
  deactivate: () => void;
  toggle: () => void;
  focusHost: () => void;
  focusPopover: () => void;
}

interface UsePopoverOptions {
  readonly deactivateOnPopoverClick?: MaybeRef<boolean | undefined>;
  readonly toggleOnHostClick?: MaybeRef<boolean | undefined>;
  readonly activateOnArrowKeydown?: MaybeRef<boolean | undefined>;
  readonly allowTab?: MaybeRef<boolean | undefined>;
}

// TODO: sided (ArrowLeft or ArrowRight to open popover, ignore shift if editable host)
// TODO: support popover arrow?

const ROOT_HOST_INJECTION_KEY: InjectionKey<Ref<HTMLElement | undefined>> = Symbol(
  __DEV__ ? 'root host' : '',
);

const EDITING_KEYS: string[] = [
  Keys.Space,
  Keys.Backspace,
  Keys.Delete,
  Keys.ArrowLeft,
  Keys.ArrowRight,
  Keys.End,
  Keys.Home,
];

export function usePopover(
  host: Ref<HTMLElement | undefined>,
  popover: Ref<HTMLElement | undefined>,
  options: UsePopoverOptions = {},
): UsePopoverReturn {
  const hostElement = computed(() => unrefElement(host));
  const popoverElement = computed(() => unrefElement(popover));

  const deactivateOnPopoverClick = computed(() => unref(options.deactivateOnPopoverClick) ?? false);
  const toggleOnHostClick = computed(() => unref(options.toggleOnHostClick) ?? true);
  const activateOnArrowKeydown = computed(() => unref(options.activateOnArrowKeydown) ?? true);
  const allowTab = computed(() => unref(options.allowTab) ?? false);

  const injectedRootHost = inject(ROOT_HOST_INJECTION_KEY, null);
  const rootHost = computed(() => injectedRootHost?.value || host.value);
  const rootHostElement = computed(() => unrefElement(rootHost));
  const nested = computed(() => !!injectedRootHost);
  provide(ROOT_HOST_INJECTION_KEY, rootHost);

  const editableHost = computed<boolean>(() => {
    return hostElement.value ? isElementEditable(hostElement.value) : false;
  });

  const active = ref(false);

  const { id: hostZoneId, active: focusZoneActive } = useFocusScope(host);
  useFocusScope(popover, { parentScopeId: hostZoneId });

  function focusHost(): void {
    if (!hostElement.value) {
      return;
    }

    setNativeFocused(hostElement.value, true, true);
  }

  function focusPopover(focusStrategy?: FocusStrategy): void {
    if (!popoverElement.value) {
      return;
    }

    let focusable;

    if (focusStrategy === 'last') {
      // The only way to get last focusable element
      const div = document.createElement('div');
      popoverElement.value.appendChild(div);
      focusable = obGetClosestFocusable({
        initial: div,
        root: popoverElement.value,
        previous: true,
      });

      popoverElement.value.removeChild(div);
    } else {
      focusable = obGetClosestFocusable({
        initial: popoverElement.value,
        root: popoverElement.value,
      });
    }

    // Maybe there are something with tabindex -1...
    if (!focusable) {
      focusable = obGetClosestFocusable({
        initial: popoverElement.value,
        root: popoverElement.value,
        keyboard: false,
      });
    }

    if (focusable) {
      setNativeFocused(focusable);
    }
  }

  function activate(focusStrategy?: FocusStrategy): void {
    if (active.value) {
      return;
    }

    active.value = true;

    // Force focus first popover element if host is not editable
    if (focusStrategy === undefined && !editableHost.value) {
      focusStrategy = 'first';
    }

    nextTick(() => {
      requestAnimationFrame(() => {
        if (!active.value) {
          return;
        }

        if (focusStrategy) {
          if (popoverElement.value?.contains(getNativeFocused())) {
            return;
          }

          focusPopover(focusStrategy);

          return;
        }

        // In case of programmatic activation we have to make sure focus is under control
        focusHost();
      });
    });
  }

  function deactivate(): void {
    if (!active.value) {
      return;
    }
    active.value = false;
  }

  function toggle(): void {
    if (active.value) {
      deactivate();
      return;
    }

    activate();
  }

  function moveFocusOut(previous = false) {
    if (!rootHostElement.value) {
      return;
    }

    let nextFocusable = obGetClosestFocusable({
      initial: rootHostElement.value,
      root: document.documentElement,
      previous,
    });

    let focusable;

    while (nextFocusable && !focusable) {
      if (
        !popoverElement.value ||
        (nextFocusable !== popoverElement.value && !popoverElement.value.contains(nextFocusable))
      ) {
        focusable = nextFocusable;
      } else {
        nextFocusable = obGetClosestFocusable({
          initial: nextFocusable as Element,
          root: document.documentElement,
          previous,
        });
      }
    }

    if (!focusable) {
      blurNativeFocused();
      return;
    }

    focusable.focus();
  }

  function getClosestFocusableInPopover(previous = false): HTMLElement | null {
    const activeElement = getNativeFocused();

    if (!activeElement || !popoverElement.value) {
      return null;
    }

    return obGetClosestFocusable({
      initial: activeElement,
      root: popoverElement.value,
      previous,
    });
  }

  useEventListener(host, 'click', (event: MouseEvent) => {
    if (!toggleOnHostClick.value) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    toggle();
  });

  useEventListener(host, 'keydown', (event: KeyboardEvent) => {
    switch (event.key) {
      case Keys.ArrowUp:
      case Keys.ArrowDown: {
        if (!activateOnArrowKeydown.value) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (!active.value) {
          activate(event.key === Keys.ArrowDown ? 'first' : 'last');
        } else {
          focusPopover(event.key === Keys.ArrowDown ? 'first' : 'last');
        }

        break;
      }
      case Keys.Escape: {
        if (nested.value && !active.value) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();

        deactivate();

        break;
      }
      case Keys.Tab: {
        if (nested.value && !active.value) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (!active.value || !allowTab.value || event.shiftKey) {
          deactivate();

          // !active.value doesn't mean popover is not rendered at this moment
          // we have to avoid moving focus on it
          moveFocusOut(event.shiftKey);
          return;
        }

        if (popoverElement.value) {
          const focusable = obGetClosestFocusable({
            initial: popoverElement.value,
            root: popoverElement.value,
          });

          if (focusable) {
            setNativeFocused(focusable);
            return;
          }
        }

        deactivate();
        moveFocusOut();

        break;
      }
      default:
        break;
    }
  });

  useEventListener(popover, 'keydown', (event: KeyboardEvent) => {
    switch (event.key) {
      case Keys.ArrowUp:
      case Keys.ArrowDown: {
        event.preventDefault();
        event.stopPropagation();

        const focusable = getClosestFocusableInPopover(event.key === Keys.ArrowUp);

        if (focusable) {
          setNativeFocused(focusable);
        }
        break;
      }
      case Keys.Escape: {
        event.preventDefault();
        event.stopPropagation();

        deactivate();
        focusHost();
        break;
      }
      case Keys.Tab: {
        if (!allowTab.value) {
          event.preventDefault();
          event.stopPropagation();

          deactivate();

          if (event.shiftKey) {
            focusHost();
          } else {
            moveFocusOut();
          }

          return;
        }

        if (event.shiftKey && !getClosestFocusableInPopover(true)) {
          event.preventDefault();
          event.stopPropagation();
          deactivate();
          focusHost();
          return;
        }

        if (!event.shiftKey && !getClosestFocusableInPopover(false)) {
          event.preventDefault();
          event.stopPropagation();
          deactivate();
          moveFocusOut();
        }

        break;
      }
      default: {
        const { key, target, defaultPrevented } = event;

        if (
          !defaultPrevented &&
          editableHost.value &&
          target instanceof HTMLElement &&
          (key.length === 1 || EDITING_KEYS.includes(key)) &&
          !isElementEditable(target)
        ) {
          focusHost();
          return;
        }

        break;
      }
    }
  });

  useEventListener(popover, 'click', (event: MouseEvent) => {
    if (!deactivateOnPopoverClick.value) {
      return;
    }

    if (event.target === popoverElement.value) {
      return;
    }

    event.stopPropagation();

    deactivate();
    focusHost();
  });

  watch(focusZoneActive, (val) => {
    if (val || !document.hasFocus()) {
      return;
    }

    deactivate();
  });

  // Case: click on a button in the popover removes button.
  // When you click on active element, focus moves out to the body and returns.
  // `useActiveElement` handles it correct way.
  // But if button removes after click, focus never returns to the popover. And popover closes itself.
  // To handle this behavior we track removed nodes and care about focus.

  let lastFocusedElement: Element | null = null;
  useMutationObserver(
    popover,
    (records) => {
      const removedNodes: Node[] = records.reduce<Node[]>((acc, { removedNodes }) => {
        const nodes = Array.from(removedNodes);
        return acc.concat(nodes);
      }, []);

      if (
        removedNodes.some(
          (node) => node === lastFocusedElement || node.contains(lastFocusedElement),
        )
      ) {
        focusPopover();
      }
    },
    { subtree: true, childList: true },
  );

  useEventListener(
    popover,
    'focusout',
    (event: FocusEvent) => {
      lastFocusedElement = event.target as Element;
    },
    true,
  );
  useEventListener(
    popover,
    'focusin',
    () => {
      lastFocusedElement = null;
    },
    true,
  );

  return {
    active: computed({
      set(value) {
        if (!value) {
          deactivate();
          return;
        }

        activate();
      },
      get() {
        return active.value;
      },
    }),
    activate,
    deactivate,
    toggle,
    focusHost,
    focusPopover,
  };
}
