<script lang="ts" setup>
import { computed, useSlots, useCssModule, h, mergeProps } from 'vue';
import { validateAsChild } from '../../utils';

interface Props {
  asChild?: boolean;
  clickable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  asChild: false,
  clickable: false,
});

const slots = useSlots();
const style = useCssModule();

const attrs = computed(() => ({
  class: [style.root, { [style.clickable]: props.clickable }],
}));

defineRender(() => {
  const children = slots.default?.();

  if (props.asChild) {
    if (!children || !validateAsChild(children)) {
      // TODO: add warning?
      return null;
    }

    return h(children[0], mergeProps(attrs.value, children[0].props ?? {}));
  }

  return h('span', attrs.value, children);
});
</script>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  @include shared.reset-button();
  cursor: default;
  display: inline-flex;
  vertical-align: top;
  color: #35416d;
  text-align: center;
  font-family: typography.$font-family-primary;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: 500;
  border-radius: 11px;
  padding: 0 8px;
  height: 22px;
  align-items: center;
  white-space: nowrap;
  background: #e6effb; // TODO: design token
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;

  &:focus-visible {
    outline: 1px solid colors.$hyperlink;
    outline-offset: -1px;
  }
}

.clickable {
  cursor: pointer;
  color: #085cd9;

  // TODO: hover/active states
}
</style>
