<script lang="ts" setup>
import { computed, inject, toValue } from 'vue';
import { IconCheck, IconGripVertical } from '@tabler/icons-vue';
import { SlickItem, DragHandle } from 'vue-slicksort';
import { AriaRole } from '../../../shared/types';
import { hasSlotContent } from '../../../utils';
import { useId } from '../../../composables';
import { ObPrimitiveCheckbox } from '../../../components/primitive-checkbox';
import { ACTION_LIST_CONTEXT } from './action-list-context';

interface Props {
  disabled?: boolean;
  role?: AriaRole;
  selected?: boolean;
  indeterminate?: boolean;
  index?: number;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  indeterminate: false,
  role: undefined,
  selected: false,
  index: undefined,
});

const emit = defineEmits<{
  select: [event: MouseEvent | KeyboardEvent];
}>();

const { selectionMode, onAfterSelect, itemRole, compact, sortable } = inject(
  ACTION_LIST_CONTEXT,
  {},
);

function onSelect(event: MouseEvent | KeyboardEvent) {
  if (event.defaultPrevented) {
    return;
  }

  event.preventDefault();

  emit('select', event);

  if (typeof onAfterSelect === 'function') {
    onAfterSelect();
  }
}

function onClick(event: MouseEvent) {
  if (event.defaultPrevented) {
    return;
  }

  if (!props.disabled) {
    onSelect(event);
  }
}

function onKeypress(event: KeyboardEvent) {
  if (!props.disabled && [' ', 'Enter'].includes(event.key)) {
    onSelect(event);
  }
}

const labelId = useId();

const attributes = computed(() => ({
  onClick,
  onKeypress,
  'aria-disabled': props.disabled ? true : undefined,
  tabIndex: props.disabled ? undefined : '0',
  'aria-labelledby': labelId,
  'aria-checked': props.selected,
  // TODO: manage all cases for `selected`
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-selected
  role: props.role || toValue(itemRole),
  tag: toValue(sortable) ? 'li' : undefined,
  index: toValue(sortable) ? props.index : undefined,
}));
</script>

<template>
  <component
    :is="sortable ? SlickItem : 'li'"
    v-bind="attributes"
    :class="[
      $style.root,
      {
        [$style.compact]: compact,
        [$style.disabled]: props.disabled,
      },
    ]"
  >
    <DragHandle v-if="sortable" tag="div" :class="$style.grip">
      <div :class="$style.gripInner">
        <IconGripVertical />
      </div>
    </DragHandle>
    <div v-if="selectionMode === 'multiple'" :class="$style.leading">
      <ObPrimitiveCheckbox
        v-if="selectionMode === 'multiple'"
        :checked="props.selected"
        :indeterminate="props.indeterminate"
        :disabled="props.disabled"
      />
    </div>
    <div v-else-if="selectionMode === 'single'" :class="$style.leading">
      <IconCheck v-if="props.selected" :size="16" />
    </div>
    <div v-if="hasSlotContent($slots.icon)" :class="$style.leading">
      <slot name="icon" />
    </div>
    <div :class="$style.container">
      <div :id="labelId" :class="$style.label">
        <slot />
      </div>
      <div v-if="hasSlotContent($slots.description)" :class="$style.description">
        <slot name="description" />
      </div>
    </div>
  </component>
</template>

<style lang="scss" module>
@use '../../../styles/colors';
@use '../../../styles/typography';

.root {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  cursor: pointer;
  border-radius: 4px;

  &:hover:not(.disabled),
  &:focus-visible {
    background: #f1effe; // TODO: use token
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px colors.$hyperlink;
    outline: none;
  }

  &:active:not(.disabled) {
    background: #e2d4ff; // TODO: use token
  }
}

.compact {
  padding: 6px 12px;
}

.disabled {
  cursor: not-allowed;
  color: #9aa0b6;
}

.grip {
  display: flex;
  padding: 2px 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 8px;
  color: #9aa0b6;
  cursor: move;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}

.root:hover .grip {
  opacity: 1;
}

.gripInner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 1em;
  height: 1em;
}

.leading {
  display: flex;
  padding: 2px 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 8px;
  font-size: 16px;
  width: 1em;
  height: 1em;
  color: #021148; // TODO: token
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  justify-content: space-between;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  gap: 0 24px;
  text-align: left;
}

.label {
  font-size: 14px;
  line-height: 20px;
}

.description {
  font-size: 12px;
  line-height: 18px;
  color: #9aa0b6; // TODO: use design token
}

.disabled .description {
  color: #c2c6d3;
}
</style>
