import { computed } from 'vue';
import type { Ref } from 'vue';
import { unrefElement } from '@vueuse/core';
import { obGetClosestFocusable, getNativeFocused, isNativeMouseFocusable } from '../../utils';

interface UseChildrenFocusControlOptions {
  root: Ref<HTMLElement>;
}

interface UseChildrenFocusControlReturn {
  focusLast: () => void;
  focusFirst: () => void;
  focusNext: () => void;
  focusPrevious: () => void;
}

export function useChildrenFocusControl(
  options: UseChildrenFocusControlOptions,
): UseChildrenFocusControlReturn {
  const root = computed(() => unrefElement(options.root));

  function focusLast() {
    if (!root.value) {
      return;
    }

    const lastChild = root.value.children[root.value.children.length - 1];

    if (isNativeMouseFocusable(lastChild)) {
      (lastChild as HTMLElement).focus();
      return;
    }

    const focusable = obGetClosestFocusable({
      initial: lastChild,
      root: root.value,
      previous: true,
      keyboard: false,
    });

    focusable?.focus();
  }

  function focusFirst() {
    if (!root.value) {
      return;
    }

    const focusable = obGetClosestFocusable({
      initial: root.value,
      root: root.value,
      keyboard: false,
    });

    focusable?.focus();
  }

  function focusNext() {
    if (!root.value) {
      return;
    }

    const focusedElement = getNativeFocused();

    if (!focusedElement || !root.value.contains(focusedElement)) {
      focusFirst();
      return;
    }

    const focusable = obGetClosestFocusable({
      initial: focusedElement,
      root: root.value,
      keyboard: false,
    });

    if (!focusable) {
      focusFirst();
    }

    focusable?.focus();
  }

  function focusPrevious() {
    if (!root.value) {
      return;
    }

    const focusedElement = getNativeFocused();

    if (!focusedElement || !root.value.contains(focusedElement)) {
      focusLast();
      return;
    }

    const focusable = obGetClosestFocusable({
      initial: focusedElement,
      root: root.value,
      previous: true,
      keyboard: false,
    });

    if (!focusable || focusable === root.value) {
      focusLast();
      return;
    }

    focusable?.focus();
  }

  return {
    focusLast,
    focusFirst,
    focusNext,
    focusPrevious,
  };
}
